import React from "react"
import { graphql } from "gatsby"

import PageLink from "../shared/pageLink"
import Person from "./person"

import Reveal from "react-reveal/Reveal"

const PersonList = ({ list, children }) => {
  console.log(list)
  return (
    <ul className={`c-cartoon-list`}>
      <Reveal effect="fadeInUp" fraction={0.05}>
        {list.map((person, index) => (
          <li
            key={index}
            className={`c-cartoon-list__item`}
            // className={`cell medium-4`}
          >
            <PageLink
              item={person.person}
              className={`c-cartoon-link`}
              style={{
                "--theme-color": person.person.theme_color1,
                "--a11y-theme-color": person.person.a11y_theme_color,
              }}
              ie-style={`--theme-color: ${person.person.theme_color1}; --a11y-theme-color: ${person.person.a11y_theme_color}`}
            >
              <Person
                person={person.person}
                classes={`c-cartoon-item--small`}
              />
              {/* <Person person={person.person} /> */}
            </PageLink>
          </li>
        ))}
      </Reveal>
    </ul>
  )
}

export default PersonList

export const query = graphql`
  fragment teamFields on PRISMIC_Our_team {
    list
    group_name
    sub_heading
    team {
      person {
        ...bioFields
      }
    }
  }
`
