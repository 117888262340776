import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
// import Emoji from "a11y-react-emoji"
import htmlSerializer from "../utils/htmlSerializer"
import Person from "../components/widgets/person"
import PersonList from "../components/widgets/personList"

import Reveal from "react-reveal/Reveal"

export const query = graphql`
  query BioQuery($uid: String) {
    prismic {
      allOur_teams {
        edges {
          node {
            ...teamFields
          }
        }
      }
      allPersons(uid: $uid) {
        edges {
          node {
            ...bioFields
            email_address
            main_content
            list
          }
        }
      }
    }
  }
`

const BioTemplate = props => {
  const doc = props.data.prismic.allPersons.edges.slice(0, 1).pop()
  console.log(props)
  if (!doc) return null
  const teamList = props.data.prismic.allOur_teams.edges.find(
    list => list.node.list === doc.node.list
  )
  // console.log(teamList)
  const relatedBios = teamList.node.team.filter(
    person => person.person._meta.uid !== doc.node._meta.uid
  )
  // console.log(relatedBios)
  return (
    <Layout
      pageTitle={teamList.node.group_name}
      parentPage={`/who-we-are#${teamList.node.group_name
        .replace(/\s+/g, "-")
        .toLowerCase()}`}
    >
      <SEO title={doc.node.name} />

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section
          className={`grid-container c-banner c-banner--image`}
          style={{
            "--theme-color": doc.node.theme_color1,
            "--a11y-theme-color": doc.node.a11y_theme_color,
          }}
          ie-style={`--theme-color: ${doc.node.theme_color1}; --a11y-theme-color: ${doc.node.a11y_theme_color}`}
        >
          <div
            className={`grid-x grid-margin-x align-justify c-banner__container`}
          >
            <div className={`cell medium-7`}>
              <div className={`c-banner__heading`}>
                <h1>
                  <div>{doc.node.name}</div>
                  <span className={`show-for-sr`}> - </span>
                  <span className={`u-color-gray`}>{doc.node.role}</span>
                </h1>
              </div>
              {/* {doc.node.email_address &&
              <a className={`c-button`} href={`mailto:${doc.node.email_address}?subject=Hey%20${doc.node.name.split(' ')[0]}%20%F0%9F%91%8B`}>
                <Emoji symbol={`👋`} label={`Wave!`} className={`c-button__symbol`} />
                Hi {doc.node.name.split(' ')[0]}
              </a>
            } */}
            </div>
            <div className={`cell medium-4 u-text-center`}>
              <Person person={doc.node} imageOnly />
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp" fraction={0.05}>
        <section className={`grid-container`}>
          <div className={`grid-x`}>
            <div className={`cell`}>
              <RichText
                render={doc.node.main_content}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>
        </section>
      </Reveal>

      <aside className={`c-aside grid-container`}>
        <div className={`c-aside__content`}>
          <Reveal effect="fadeInUp" fraction={0.05}>
            <div className={`grid-x`}>
              <div className={`cell`}>
                <h2 className={`u-padding-top`}>{teamList.node.sub_heading}</h2>
              </div>
              <div className={`cell`}>
                <PersonList list={relatedBios} />
              </div>
            </div>
          </Reveal>
        </div>
      </aside>
    </Layout>
  )
}

export default BioTemplate
