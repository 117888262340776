import React from "react"
import { graphql } from 'gatsby'

// import Image from "../shared/image";
import SVG from 'react-inlinesvg';

const Person = ({ person, imageOnly, classes }) => {
  
  return (
    <div className={`c-cartoon-item ${classes ? classes : ``}`}>
        <div className={`c-cartoon-item__image`}>
          <SVG src={person.cartoon_image.url} uniquifyIDs={true} />
        </div>
        {!imageOnly &&
          <div className={`c-cartoon-item__content`}>
            <strong>{person.name}</strong>
            {person.role}
          </div>
        }
    </div>
    // <div className={`o-arrow-link`}>
    //   {!imageOnly &&
    //       <div>
    //         <strong className={`o-arrow-link__text`}>{person.name}</strong>
    //         <div>{person.role}</div>
    //       </div>
    //     }
    // </div>
  )
}

export default Person

export const query = graphql`
  fragment bioFields on PRISMIC_Person {
    _linkType
    _meta {
      uid
      type
    }
    list
    email_address
    role
    name
    theme_color1
    a11y_theme_color
    cartoon_image
  }
`